import React, { FC } from "react";
import { Translate } from "../../components/translations";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";

const GDPR: FC = () => (
  <div className="content-wrapper">
    <PageHero
      title="HEXOWATCH_GDPR_TITLE"
    />
    <Layout>
      <Section>
        <div className="col-lg-12">
          <Translate name="HEXOWATCH_GDPR_CONTENT" />
        </div>
      </Section>
    </Layout>
  </div>
);

export default GDPR;
